import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'

// hooks
// types
// components
import { masks } from 'src/utils/masks'
import { format } from 'date-fns'
import { useState } from 'react'
import Iconify from 'src/components/iconify'
//

// ----------------------------------------------------------------------

type Props = {
  selected: boolean
  onEditRow: VoidFunction
  row: any
  refetch: VoidFunction
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
}

const paymentTypes: any = {
  money: 'Dinheiro',
  credit: 'Cartão de Crédito',
  debit: 'Cartão de Débito',
  pix: 'Pix',
  card: 'CashLess',
}

export default function OrderTableRow({
  row,
  selected,
  refetch,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { event, products, createdAt, saler, paymentType, comission, tax, charge, total } = row

  const [open, setOpen] = useState(false)

  console.log(row)
  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {!open ? <Iconify icon='mingcute:down-fill' /> : <Iconify icon='mingcute:up-fill' />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>{event?.name}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{saler?.name}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{paymentTypes[paymentType]}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{masks.currencyAllPlatforms(total)}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{masks.currencyAllPlatforms(tax)}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{masks.currencyAllPlatforms(comission)}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{masks.currencyAllPlatforms(charge)}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 10 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Table size='small' aria-label='purchases'>
              <TableHead>
                <TableRow>
                  <TableCell>Produto</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell align='right'>Quantidade</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products?.map((product: any) => (
                  <TableRow key={product._id}>
                    <TableCell component='th' scope='row'>
                      {product.product?.name}
                    </TableCell>
                    <TableCell>{masks.currencyAllPlatforms(product?.price)}</TableCell>
                    <TableCell align='right'>{product.quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
