import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
// @mui
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
// types
// components
import Iconify from 'src/components/iconify'
import CustomPopover, { usePopover } from 'src/components/custom-popover'

import useEvents from 'src/service/events.service'
import useDebounce from 'src/hooks/use-debounce'
import { OptionType } from 'src/components/hook-form/rhf-autocomplete'
import {
  Autocomplete,
  Checkbox,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { IOrderTableFilterValue, IOrderTableFilters } from 'src/types/orders'
import useUsers from 'src/service/users.service'
import { getCategoriesToAutocomplete } from 'src/service/categories.service'

// ----------------------------------------------------------------------

const paymentTypes: any = [
  { value: 'money', label: 'Dinheiro' },
  { value: 'credit', label: 'Cartão de Crédito' },
  { value: 'debit', label: 'Cartão de Débito' },
  { value: 'pix', label: 'Pix' },
  { value: 'card', label: 'CashLess' },
]

type Props = {
  filters: IOrderTableFilters
  onFilters: (name: string, value: IOrderTableFilterValue) => void
}

export default function OrderTableToolbar({ filters, onFilters }: Props) {
  const popover = usePopover()

  const handleFilterStartDate = useCallback(
    (newValue: Date | null) => {
      onFilters('dateStart', newValue)
    },
    [onFilters],
  )

  const handleFilterEndDate = useCallback(
    (newValue: Date | null) => {
      onFilters('dateEnd', newValue)
    },
    [onFilters],
  )

  const handleFilterPaymentType = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'paymentType',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,
      )
    },
    [onFilters],
  )

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <SelectEvents onFilters={onFilters} filters={filters} />

          {filters.event?.value && <SelectCategory onFilters={onFilters} filters={filters} />}

          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Meio de pagamento</InputLabel>

            <Select
              multiple
              value={filters.paymentType}
              onChange={handleFilterPaymentType}
              input={<OutlinedInput label='Meio de pagamento' />}
              renderValue={(selected) =>
                selected.map((value) => JSON.parse(value).label).join(', ')
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {paymentTypes.map((option: any) => (
                <MenuItem key={option.value} value={JSON.stringify(option)}>
                  <Checkbox
                    disableRipple
                    size='small'
                    checked={
                      !!filters.paymentType.find(
                        (cat: any) => JSON.parse(cat).value === option.value,
                      )
                    }
                  />
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DateTimePicker
            label='Data Inícial'
            value={filters.dateStart}
            format='dd/MM/yyyy HH:mm'
            onChange={handleFilterStartDate}
            slotProps={{ textField: { fullWidth: true } }}
            sx={{
              maxWidth: { md: 180 },
            }}
          />

          <DateTimePicker
            label='Data Final'
            value={filters.dateEnd}
            format='dd/MM/yyyy HH:mm'
            onChange={handleFilterEndDate}
            slotProps={{ textField: { fullWidth: true } }}
            sx={{
              maxWidth: { md: 180 },
            }}
          />
          <IconButton
            sx={{
              marginLeft: 'auto',
            }}
            onClick={popover.onOpen}
          >
            <Iconify icon='eva:more-vertical-fill' />
          </IconButton>
        </Stack>

        <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <SelectVendedores onFilters={onFilters} filters={filters} />
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow='right-top'
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:printer-minimalistic-bold' />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:import-bold' />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:export-bold' />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  )
}

export const SelectEvents = ({ onFilters, filters }: { onFilters: any; filters: any }) => {
  const [query, setQuery] = useState('')
  const searchDebounce = useDebounce(query, 500)

  const { isLoading, isFetching, data } = useEvents(
    {
      page: 1,
      perPage: 100,
      sort: undefined,
    },
    {
      search: searchDebounce,
    },
  )

  const handleFilterService = useCallback(
    (event: SyntheticEvent<Element, Event>, selectedValue: OptionType | null) => {
      onFilters('event', selectedValue)
      if (!selectedValue) {
        onFilters('categories', null)
      }
    },
    [onFilters],
  )

  return (
    <FormControl
      sx={{
        flexShrink: 0,
        width: { xs: 1, md: 200 },
      }}
    >
      <Autocomplete
        id='event-list'
        options={data?.data?.map((option) => ({ label: option?.name, value: option?._id })) || []}
        isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
        getOptionLabel={(option: OptionType | string) => (option as OptionType)?.label}
        onChange={handleFilterService}
        value={filters.event}
        loading={isLoading || isFetching}
        inputValue={query}
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue)
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        renderInput={(params) => <TextField label='Eventos' {...params} />}
      />
    </FormControl>
  )
}

export const SelectCategory = ({ onFilters, filters }: { onFilters: any; filters: any }) => {
  const [query, setQuery] = useState('')
  const [data, setData] = useState<OptionType[]>([]) // Define a type if you have one
  const searchDebounce = useDebounce(query, 500)

  useEffect(() => {
    const fetchData = async () => {
      const result = await getCategoriesToAutocomplete(searchDebounce, filters.event?.value)
      // @ts-ignore
      setData(result || [])
    }

    fetchData()
  }, [searchDebounce, filters.event?.value])

  const handleFilterService = useCallback(
    (event: SyntheticEvent<Element, Event>, selectedValue: OptionType | null) => {
      onFilters('categories', selectedValue)
    },
    [onFilters],
  )

  return (
    <FormControl
      sx={{
        flexShrink: 0,
        width: { xs: 1, md: 200 },
      }}
    >
      <Autocomplete
        id='category-list'
        // @ts-ignore
        options={data || []}
        isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
        getOptionLabel={(option: OptionType | string) => (option as OptionType)?.label}
        // @ts-ignore
        onChange={handleFilterService}
        inputValue={query}
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue)
        }}
        renderInput={(params) => <TextField label='Categorias' {...params} />}
      />
    </FormControl>
  )
}

export const SelectVendedores = ({ onFilters, filters }: { onFilters: any; filters: any }) => {
  const [query, setQuery] = useState('')
  const searchDebounce = useDebounce(query, 500)

  const { isLoading, isFetching, data } = useUsers(
    {
      page: 1,
      perPage: 100,
      sort: undefined,
    },
    {
      search: searchDebounce,
    },
  )

  const handleFilterService = useCallback(
    (event: SyntheticEvent<Element, Event>, selectedValues: any[]) => {
      onFilters('salers', selectedValues)
    },
    [onFilters],
  )

  return (
    <FormControl
      sx={{
        flexShrink: 0,
        width: '100%',
      }}
    >
      <Autocomplete
        id='event-list'
        multiple
        options={data?.data?.map((option) => ({ label: option?.name, value: option?._id })) || []}
        isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
        getOptionLabel={(option: OptionType | string) => (option as OptionType)?.label}
        onChange={handleFilterService}
        value={filters.salers}
        loading={isLoading || isFetching}
        inputValue={query}
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue)
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        renderInput={(params) => <TextField label='Vendedores' {...params} />}
      />
    </FormControl>
  )
}
