// @mui
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Stack, { StackProps } from '@mui/material/Stack'
// types

// components
import Iconify from 'src/components/iconify'
import { IReportTableFilterValue, IReportTableFilters } from 'src/types/reports'
import { shortDateLabel } from 'src/utils/utils'

// ----------------------------------------------------------------------

type Props = StackProps & {
  filters: IReportTableFilters
  onFilters: (name: string, value: IReportTableFilterValue) => void
  onResetFilters: VoidFunction
  results: number
}

export default function ReportTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}: Props) {
  const handleRemoveEvent = () => {
    onFilters('event', null)
  }

  const handleRemoveDate = () => {
    onFilters('dateStart', null)
    onFilters('dateEnd', null)
  }

  const shortLabel = shortDateLabel(filters.dateStart, filters.dateEnd)

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component='span' sx={{ color: 'text.secondary', ml: 0.25 }}>
          resultados encontrados
        </Box>
      </Box>

      <Stack flexGrow={1} spacing={1} direction='row' flexWrap='wrap' alignItems='center'>
        {!!filters.event && (
          <Block label='Evento:'>
            <Chip
              key={filters.event?.value}
              label={filters.event?.label}
              size='small'
              onDelete={() => handleRemoveEvent()}
            />
          </Block>
        )}

        {filters.dateStart && filters.dateEnd && (
          <Block label='Data:'>
            <Chip size='small' label={shortLabel} onDelete={handleRemoveDate} />
          </Block>
        )}

        <Button
          color='error'
          onClick={onResetFilters}
          startIcon={<Iconify icon='solar:trash-bin-trash-bold' />}
        >
          Limpar
        </Button>
      </Stack>
    </Stack>
  )
}

// ----------------------------------------------------------------------

type BlockProps = StackProps & {
  label: string
}

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant='outlined'
      spacing={1}
      direction='row'
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component='span' sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction='row' flexWrap='wrap'>
        {children}
      </Stack>
    </Stack>
  )
}
