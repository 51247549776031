import isEqual from 'lodash/isEqual'
import { useState, useCallback } from 'react'
// @mui
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { alpha } from '@mui/material/styles'

// routes
import { paths } from 'src/routes/paths'
import { useRouter } from 'src/routes/hook'
import { RouterLink } from 'src/routes/components'
// types
import { IUserTableFilterValue } from 'src/types/user'
// components
import Iconify from 'src/components/iconify'
import { useSettingsContext } from 'src/components/settings'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs'

import Datagrid from 'src/components/datagrid'

//
import { Helmet } from 'react-helmet-async'

import axiosInstance from 'src/utils/axios'
import { useSnackbar } from 'notistack'
import { useBoolean } from 'src/hooks/use-boolean'
import useProducts from 'src/service/products.service'
import { ICategorieTableFilters } from 'src/types/categorie'
import ProductCreateEditForm from './products-create-edit-view'
import ProductTableRow from './table-user/product-table-row'
import ProductTableToolbar from './table-user/product-table-toolbar'
import ProductTableFiltersResult from './table-user/product-table-filters-result'

export const USER_STATUS_OPTIONS = [
  { value: 'true', label: 'Ativos' },
  { value: 'false', label: 'Desativados' },
]

const STATUS_OPTIONS = [{ value: 'all', label: 'Todos' }, ...USER_STATUS_OPTIONS]

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', width: 200 },
  { id: 'price', label: 'Preço', width: 100 },
  { id: 'status', label: 'Status', width: 100 },
  { id: 'createdAt', label: 'Data de criação', width: 100 },
  { id: '', width: 88 },
]

const defaultPagination = {
  page: 1,
  perPage: 8,
  sort: {
    createdAt: 'desc',
  },
}
const defaultFilters: ICategorieTableFilters = {
  active: 'all',
  search: '',
  categories: [],
}
export default function ProductsListView() {
  const [pagination, setPagination] = useState(defaultPagination)
  const [filters, setFilters] = useState<ICategorieTableFilters>(defaultFilters)

  const { isLoading, isFetching, data, refetch } = useProducts(pagination, filters)

  const settings = useSettingsContext()

  const { enqueueSnackbar } = useSnackbar()

  const router = useRouter()

  const quickEdit = useBoolean()

  const canReset = !isEqual(defaultFilters, filters)

  const notFound = (!data?.data.length && canReset) || !data?.data.length

  const handleFilters = useCallback((name: string, value: IUserTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  const handleDeleteRow = useCallback(
    async (id: string) => {
      try {
        await axiosInstance.delete(`/product/${id}`)

        enqueueSnackbar('Produto removido com sucesso !')
        refetch()
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro ao remover Produto !', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, refetch],
  )

  const handleDeleteRows = useCallback(
    async (selectedValues: string[]) => {
      try {
        selectedValues.map(async (id) => {
          await axiosInstance.delete(`/product/${id}`)
        })

        enqueueSnackbar('Produtos removido com sucesso !')
        refetch()
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro ao remover usuario !', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, refetch],
  )

  const handleEditRow = useCallback((id: string) => {
    // router.push(paths.dashboard.user.edit(id))
  }, [])

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('active', newValue)
    },
    [handleFilters],
  )

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters)
  }, [])

  return (
    <>
      <Helmet>
        <title>Produtos - Listagem</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Produtos'
          links={[{ name: 'Início', href: paths.dashboard.root }, { name: 'Produtos' }]}
          action={
            <Button
              component={RouterLink}
              onClick={() => quickEdit.onTrue()}
              variant='contained'
              startIcon={<Iconify icon='mingcute:add-line' />}
            >
              Novo produto
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.active}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab.value} iconPosition='end' value={tab.value} label={tab.label} />
            ))}
          </Tabs>
          <ProductTableToolbar filters={filters} onFilters={handleFilters} />

          {canReset && (
            <ProductTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={data?.data.length || 0}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Datagrid
            data={data?.data || []}
            notFound={notFound}
            isLoading={isLoading || isFetching}
            headLabel={TABLE_HEAD}
            refetch={refetch}
            total={data?.total || 0}
            setPagination={setPagination}
            pagination={pagination}
            handleDeleteRows={handleDeleteRows}
            ItemContent={ProductTableRow}
            handleDeleteRow={handleDeleteRow}
            handleEditRow={handleEditRow}
          />
        </Card>
      </Container>
      <ProductCreateEditForm
        onRefleshTable={() => refetch()}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />
    </>
  )
}
