// eslint-disable

import { useQuery } from '@tanstack/react-query'
import { Pagination } from 'src/components/datagrid'

import axiosInstance from 'src/utils/axios'

async function fetchUser(pagination: Pagination, filters: any): Promise<any[]> {
  let sortName: any = 'createdAt'
  let order: string = 'desc'

  if (pagination.sort) {
    const keyNames = Object.keys(pagination.sort)
    sortName = keyNames[0]
    order = pagination.sort[sortName]
  }

  const res = await axiosInstance.get('/product/sales', {
    params: {
      ...pagination,
      ...filters,
      sort: sortName,
      order: order === 'desc' ? -1 : 1,
      event: filters?.event?.value,
    },
  })
  return res.data
}

function useReports(pagination: Pagination, filters?: any) {
  return useQuery(['reports', pagination, filters], () => fetchUser(pagination, filters), {
    keepPreviousData: true,
    staleTime: 5000,
  })
}

export default useReports
