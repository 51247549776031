// @mui
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
// types
// components
import { fNumber } from 'src/utils/format-number'
import { masks } from 'src/utils/masks'
import { Tooltip } from '@mui/material'

// ----------------------------------------------------------------------

type Props = {
  isLoading: boolean
  data: any
}

export default function OrdersCards({ isLoading, data }: Props) {
  if (isLoading) {
    return (
      <Stack direction='row' flexWrap='wrap' gap={3}>
        {Array.from(new Array(12)).map((_, index) => (
          <SkeletonColor key={index} />
        ))}
      </Stack>
    )
  }
  return (
    <Stack direction='row' flexWrap='wrap' gap={3}>
      <CardItems title='Total de transações' total={data?.total} />
      <CardItems
        title='Total de transações (Valor)'
        money
        total={masks.currencyAllPlatforms(data?.totalValue)}
      />
      {/*   <CardItems
        title='Comissão Garçom'
        money
        total={masks.currencyAllPlatforms(data?.comission)}
      /> */}
      <CardItems
        title='Valor líquido total'
        money
        total={masks.currencyAllPlatforms((data?.totalValue || 0) - (data?.totalTax || 0) || 0)}
      />
      <CardItems
        title='Total de taxa: Dinheiro'
        money
        total={masks.currencyAllPlatforms(data?.tax?.money || 0)}
      />
      <CardItems
        title='Total de taxa: Crédito'
        money
        total={masks.currencyAllPlatforms(data?.tax?.credit || 0)}
      />
      <CardItems
        title='Total de taxa: Débito'
        money
        total={masks.currencyAllPlatforms(data?.tax?.debit || 0)}
      />
      <CardItems
        title='Total de taxa: PIX'
        money
        total={masks.currencyAllPlatforms(data?.tax?.pix || 0)}
      />
      <CardItems
        title='Taxa sistema'
        money
        total={masks.currencyAllPlatforms(data?.totalTax || 0)}
      />
      <CardItems
        title='Repasse Fornecedor'
        money
        total={masks.currencyAllPlatforms(data.organizedTax - data.totalTax! || 0)}
      />
      <CardItems
        title='Taxa Operação'
        money
        total={masks.currencyAllPlatforms(data?.taxOperational || 0)}
      />
      <CardItems
        title='Comissão sob vendas'
        money
        /* extraInfo='*R$ 1000,00' */
        total={masks.currencyAllPlatforms(
          Number(data?.transferTax || 0) - Number(data?.taxOperational || 0) || 0,
        )}
      />

      <CardItems
        title='Repasse organizador'
        money
        extraInfo={`Taxa operação(${masks.currencyAllPlatforms(
          data?.taxOperational || 0,
        )}) + Comissão sob vendas(${masks.currencyAllPlatforms(
          Number(data?.transferTax || 0) - Number(data?.taxOperational || 0) || 0,
        )}) - Taxa sistema(R$0,00)`}
        total={masks.currencyAllPlatforms(data.transferTax! /* - data.totalTax! */ || 0)}
      />
    </Stack>
  )
}

type CardItemsProps = {
  title: string
  total: any
  money?: boolean
  extraInfo?: string
}

const CardItems = ({ title, total, money, extraInfo }: CardItemsProps) => (
  <Tooltip title={extraInfo}>
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant='subtitle2' paragraph>
          {title}
        </Typography>

        <Typography variant='h3' gutterBottom>
          {money ? total : fNumber(total)}
        </Typography>
      </Box>
    </Card>
  </Tooltip>
)

function SkeletonColor() {
  return (
    <Skeleton
      sx={{
        borderRadius: 2,
      }}
      variant='rectangular'
      width={170}
      height={150}
    />
  )
}
