import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

// types
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'

import { IProductAccount, IProductItem } from 'src/types/product'
import { ProductSchema } from 'src/validators/product.schemas'
import { masks } from 'src/utils/masks'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: IProductItem
}

export default function ProductCreateEditForm({
  onRefleshTable,
  currentUser,
  open,
  onClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
      price: currentUser?.price ? masks.currency(currentUser.price) : '',
      description: currentUser?.description || '',
      active: currentUser?.active,
    }),
    [currentUser],
  )

  const methods = useForm<IProductAccount>({
    resolver: yupResolver(ProductSchema),
    defaultValues,
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: IProductAccount) => {
      try {
        data.price = masks.unmask(data.price)

        if (currentUser) {
          await axiosInstance.put(`/product/${currentUser._id}`, data)
        } else {
          await axiosInstance.post('/product', data)
        }

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar(
          currentUser ? 'Alteração realizada com sucesso!' : 'Produto criado com sucesso!',
        )
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, onClose, reset, currentUser, onRefleshTable],
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{currentUser ? 'Atualizar Produto' : 'Cadastrar Produto'}</DialogTitle>

        <DialogContent>
          <Box rowGap={2} columnGap={2} padding={1} display='grid'>
            <RHFTextField name='name' label='Nome' />
            <RHFTextField name='price' mask='currency' label='Preço' />
            <RHFTextField name='description' label='Descrição' />

            {currentUser && (
              <FormControlLabel
                labelPlacement='start'
                control={
                  <Controller
                    name='active'
                    control={control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(event) => field.onChange(event.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <>
                    <Typography variant='subtitle2' sx={{ mb: 0.5 }}>
                      Status
                    </Typography>
                    <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                      Aplicar desativação
                    </Typography>
                  </>
                }
                sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
              />
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton type='submit' variant='contained' loading={isSubmitting}>
            {currentUser ? 'Atualizar' : 'Cadastrar'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
