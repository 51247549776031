import { SyntheticEvent, useCallback, useState } from 'react'
// @mui
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'

// types
// components
import Iconify from 'src/components/iconify'
import CustomPopover, { usePopover } from 'src/components/custom-popover'

import { IReportTableFilterValue, IReportTableFilters } from 'src/types/reports'
import useEvents from 'src/service/events.service'
import useDebounce from 'src/hooks/use-debounce'
import { OptionType } from 'src/components/hook-form/rhf-autocomplete'
import { Autocomplete } from '@mui/material'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'

// ----------------------------------------------------------------------

type Props = {
  filters: IReportTableFilters
  onFilters: (name: string, value: IReportTableFilterValue) => void
}

export default function ReportTableToolbar({ filters, onFilters }: Props) {
  const popover = usePopover()

  const [query, setQuery] = useState('')
  const searchDebounce = useDebounce(query, 500)

  const { isLoading, isFetching, data } = useEvents(
    {
      page: 1,
      perPage: 100,
      sort: undefined,
    },
    {
      search: searchDebounce,
    },
  )

  const handleFilterStartDate = useCallback(
    (newValue: Date | null) => {
      onFilters('dateStart', newValue)
    },
    [onFilters],
  )

  const handleFilterEndDate = useCallback(
    (newValue: Date | null) => {
      onFilters('dateEnd', newValue)
    },
    [onFilters],
  )

  const handleFilterService = useCallback(
    (event: SyntheticEvent<Element, Event>, selectedValue: OptionType | null) => {
      onFilters('event', selectedValue)
    },
    [onFilters],
  )

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 300 },
            }}
          >
            <Autocomplete
              id='event-list'
              options={
                data?.data?.map((option) => ({ label: option?.name, value: option?._id })) || []
              }
              isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
              getOptionLabel={(option: OptionType | string) => (option as OptionType)?.label}
              onChange={handleFilterService}
              value={filters.event}
              loading={isLoading || isFetching}
              inputValue={query}
              onInputChange={(event, newInputValue) => {
                setQuery(newInputValue)
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => <TextField label='Eventos' {...params} />}
            />
          </FormControl>
          <DateTimePicker
            label='Data Inícial'
            value={filters.dateStart}
            format='dd/MM/yyyy HH:mm'
            onChange={handleFilterStartDate}
            slotProps={{ textField: { fullWidth: true } }}
            sx={{
              maxWidth: { md: 180 },
            }}
          />

          <DateTimePicker
            label='Data Final'
            format='dd/MM/yyyy HH:mm'
            value={filters.dateEnd}
            onChange={handleFilterEndDate}
            slotProps={{ textField: { fullWidth: true } }}
            sx={{
              maxWidth: { md: 180 },
            }}
          />

          <IconButton
            sx={{
              marginLeft: 'auto',
            }}
            onClick={popover.onOpen}
          >
            <Iconify icon='eva:more-vertical-fill' />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow='right-top'
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:printer-minimalistic-bold' />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:import-bold' />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:export-bold' />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  )
}
