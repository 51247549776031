import yup from 'src/libs/yup'

export const NewUserSchema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  email: yup
    .string()
    .required('Email é obrigatório')
    .email('O e-mail deve ser um endereço de e-mail válido'),
  photo: yup.mixed().nullable(),
  password: yup.string().required().min(6, 'Senha deve ter pelo menos 6 caracteres'),
  role: yup.string().required('Papel é obrigatório'),
  allowedPayments: yup.object().shape({
    pix: yup.boolean().default(true),
    credit: yup.boolean().default(true),
    debit: yup.boolean().default(true),
    money: yup.boolean().default(true),
    cashless: yup.boolean().default(true),
  }),
})

export const EditUserSchema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  email: yup
    .string()
    .required('Email é obrigatório')
    .email('O e-mail deve ser um endereço de e-mail válido'),
  photo: yup.mixed().nullable(),
  password: yup.string(),
  role: yup.string().required('Papel é obrigatório'),
  allowedPayments: yup
    .object()
    .shape({
      pix: yup.boolean().default(true),
      credit: yup.boolean().default(true),
      debit: yup.boolean().default(true),
      money: yup.boolean().default(true),
      cashless: yup.boolean().default(true),
    })
    .nullable(),
})
