import yup from 'src/libs/yup'

export const EventStepOneSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  date: yup.date().required().label('Data do evento'),
})

export const EventStepTwoSchema = yup.object().shape({
  managers: yup.array().of(yup.mixed()).required().min(1).label('Vendedores'),
})
