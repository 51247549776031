// eslint-disable

import { useQuery } from '@tanstack/react-query'
import { Pagination } from 'src/components/datagrid'
import { IEventItem } from 'src/types/events'

import axiosInstance from 'src/utils/axios'

type Events = {
  data: IEventItem[]
  page: number
  perPage: number
  totalPages: number
  total: number
}

async function fetchUser(pagination: Pagination, filters: any): Promise<Events> {
  let sortName: any = 'createdAt'
  let order: string = 'desc'

  if (pagination.sort) {
    const keyNames = Object.keys(pagination.sort)
    sortName = keyNames[0]
    order = pagination.sort[sortName]
  }

  const res = await axiosInstance.get('/event', {
    params: {
      ...pagination,
      ...filters,
      sort: sortName,
      order: order === 'desc' ? -1 : 1,
    },
  })
  return res.data
}

function useEvents(pagination: Pagination, filters?: any) {
  return useQuery(['events', pagination, filters], () => fetchUser(pagination, filters), {
    keepPreviousData: true,
    staleTime: 5000,
  })
}

export default useEvents
