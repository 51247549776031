// @mui
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Stack, { StackProps } from '@mui/material/Stack'
// types
import { ICategorieTableFilterValue, ICategorieTableFilters } from 'src/types/categorie'

// components
import Iconify from 'src/components/iconify'

// ----------------------------------------------------------------------

type Props = StackProps & {
  filters: ICategorieTableFilters
  onFilters: (name: string, value: ICategorieTableFilterValue) => void
  //
  onResetFilters: VoidFunction
  //
  results: number
}

const convertName: any = {
  all: 'Todos',
  true: 'Ativos',
  false: 'Desativados',
}
export default function ProductTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}: Props) {
  const handleRemoveStatus = () => {
    onFilters('active', 'all')
  }

  const handleRemoveRole = (inputValue: string) => {
    const newValue = filters.categories?.filter((item) => item !== inputValue)
    onFilters('role', newValue)
  }

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component='span' sx={{ color: 'text.secondary', ml: 0.25 }}>
          resultados encontrados
        </Box>
      </Box>

      <Stack flexGrow={1} spacing={1} direction='row' flexWrap='wrap' alignItems='center'>
        {filters.active !== 'all' && (
          <Block label='Status:'>
            <Chip size='small' label={convertName[filters.active]} onDelete={handleRemoveStatus} />
          </Block>
        )}

        {!!filters.categories.length && (
          <Block label='Categorias:'>
            {filters.categories.map((item) => {
              const category = JSON.parse(item)
              return (
                <Chip
                  key={category?._id}
                  label={category?.label}
                  size='small'
                  onDelete={() => handleRemoveRole(item)}
                />
              )
            })}
          </Block>
        )}

        <Button
          color='error'
          onClick={onResetFilters}
          startIcon={<Iconify icon='solar:trash-bin-trash-bold' />}
        >
          Limpar
        </Button>
      </Stack>
    </Stack>
  )
}

// ----------------------------------------------------------------------

type BlockProps = StackProps & {
  label: string
}

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant='outlined'
      spacing={1}
      direction='row'
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component='span' sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction='row' flexWrap='wrap'>
        {children}
      </Stack>
    </Stack>
  )
}
