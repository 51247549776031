import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
// hooks
// types
// components
import { masks } from 'src/utils/masks'
//

// ----------------------------------------------------------------------

type Props = {
  selected: boolean
  onEditRow: VoidFunction
  row: any
  refetch: VoidFunction
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
}

export default function ReportsTableRow({
  row,
  selected,
  refetch,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, stock, price, cost, totalSale, saida, stockCost, actualStock } = row

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>{name}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{stock}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{saida}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{masks.currencyAllPlatforms(price)}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{masks.currencyAllPlatforms(totalSale)}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{actualStock}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{masks.currencyAllPlatforms(cost)}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{masks.currencyAllPlatforms(stockCost)}</TableCell>
    </TableRow>
  )
}
