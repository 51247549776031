import { useCallback, useEffect, useState } from 'react'
// @mui
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Select, { SelectChangeEvent } from '@mui/material/Select'
// types
import { IUserTableFilterValue } from 'src/types/user'
// components
import Iconify from 'src/components/iconify'
import CustomPopover, { usePopover } from 'src/components/custom-popover'
import useCategories from 'src/service/categories.service'
import { ICategorieTableFilters } from 'src/types/categorie'

// ----------------------------------------------------------------------

type Props = {
  filters: ICategorieTableFilters
  onFilters: (name: string, value: IUserTableFilterValue) => void
}

export default function ProductTableToolbar({ filters, onFilters }: Props) {
  const popover = usePopover()

  const [query, setQuery] = useState(filters.search)
  const [debouncedQuery, setDebouncedQuery] = useState(filters.search)

  const { isLoading, isFetching, data } = useCategories(
    {
      page: 1,
      perPage: 100,
      sort: undefined,
    },
    {},
  )

  const handleFilterName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }, [])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [query])

  useEffect(() => {
    if (debouncedQuery !== filters.search) {
      onFilters('search', debouncedQuery)
    }
  }, [debouncedQuery, filters.search, onFilters])

  const handleFilterCategoria = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'categories',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,
      )
    },
    [onFilters],
  )

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Categorias</InputLabel>

          <Select
            multiple
            value={filters.categories}
            onChange={handleFilterCategoria}
            input={<OutlinedInput label='Categorias' />}
            renderValue={(selected) => selected.map((value) => JSON.parse(value).label).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {data?.data
              .map((option) => ({ label: option?.name, value: option?._id }))
              .map((option: any) => (
                <MenuItem key={option.value} value={JSON.stringify(option)}>
                  <Checkbox
                    disableRipple
                    size='small'
                    checked={
                      !!filters.categories.find(
                        (cat: any) => JSON.parse(cat).value === option.value,
                      )
                    }
                  />
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={query}
            onChange={handleFilterName}
            placeholder='Buscar...'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          <IconButton onClick={popover.onOpen}>
            <Iconify icon='eva:more-vertical-fill' />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow='right-top'
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:printer-minimalistic-bold' />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:import-bold' />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose()
          }}
        >
          <Iconify icon='solar:export-bold' />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  )
}
