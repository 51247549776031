import { useCallback, useMemo } from 'react'
// @mui
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import { IEventItem } from 'src/types/events'
import { yupResolver } from '@hookform/resolvers/yup'
import { EventStepOneSchema } from 'src/validators/event.schema'
import { Controller, useForm } from 'react-hook-form'

// ----------------------------------------------------------------------

interface EventStepperOneProps {
  activeStep: number
  methods?: any
  currentUser?: IEventItem
  handleBack: () => void
  handleNext: (data: any) => void
}

interface IEventStepOne {
  name: string
  description: string
  address: string
  local: string
  hours: {
    open: { type: String }
    startShow: { type: String }
  }
  date: Date
  tax: {
    credit: number
    debit: number
    money: number
    pix: number
  }
  taxConfig: {
    credit: boolean
    debit: boolean
    money: boolean
    pix: boolean
    card: boolean
  }
  superPassword: string
  extraCost: number
  obversation: string
}

export default function EventStepperOne({
  activeStep,
  handleBack,
  handleNext,
  methods,
}: EventStepperOneProps) {
  const { handleSubmit, control, watch } = methods

  const taxConfig = watch('taxConfig')

  const onSubmit = useCallback(
    async (data: IEventStepOne) => {
      handleNext(data)
    },
    [handleNext],
  )

  return (
    <>
      <Stack gap={3}>
        <RHFTextField name='name' label='Nome' />
        <RHFTextField name='description' multiline minRows={3} label='Descrição' />
        <Box
          rowGap={3}
          columnGap={2}
          display='grid'
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField name='address' label='Endereço' />
          <RHFTextField name='local' label='Local do evento' />
          <RHFTextField name='hours.open' label='Horario de inicio do show' />
          <RHFTextField name='hours.startShow' label='Horario de abertura' />
          <Controller
            name='date'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label='Data do evento'
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue)
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
              />
            )}
          />
        </Box>
        <Typography variant='subtitle2'>Taxas: </Typography>

        <Box
          rowGap={3}
          columnGap={2}
          display='grid'
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(4, 1fr)',
          }}
        >
          <Stack>
            <FormControlLabel
              labelPlacement='end'
              control={
                <Controller
                  name='taxConfig.money'
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  )}
                />
              }
              label={
                <RHFTextField
                  size='small'
                  disabled={!taxConfig?.money}
                  mask='number'
                  name='tax.money'
                  label='Dinheiro'
                />
              }
              sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
            />
          </Stack>
          <Stack>
            <FormControlLabel
              labelPlacement='end'
              control={
                <Controller
                  name='taxConfig.debit'
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  )}
                />
              }
              label={
                <RHFTextField
                  size='small'
                  disabled={!taxConfig?.debit}
                  mask='number'
                  name='tax.debit'
                  label='Débito'
                />
              }
              sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
            />
          </Stack>
          <Stack>
            <FormControlLabel
              labelPlacement='end'
              control={
                <Controller
                  name='taxConfig.pix'
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  )}
                />
              }
              label={
                <RHFTextField
                  size='small'
                  disabled={!taxConfig?.pix}
                  mask='number'
                  name='tax.pix'
                  label='Pix'
                />
              }
              sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
            />
          </Stack>
          <Stack>
            <FormControlLabel
              labelPlacement='end'
              control={
                <Controller
                  name='taxConfig.credit'
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  )}
                />
              }
              label={
                <RHFTextField
                  size='small'
                  disabled={!taxConfig?.credit}
                  mask='number'
                  name='tax.credit'
                  label='Crédito'
                />
              }
              sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
            />
          </Stack>
        </Box>

        <Typography variant='subtitle2'>Extras: </Typography>

        <Box
          rowGap={3}
          columnGap={2}
          display='grid'
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField name='superPassword' label='Senha administrativa' />
          <RHFTextField name='extraCost' label='Custos extras(R$)' />
        </Box>
        <RHFTextField name='obversation' minRows={3} multiline label='Observações' />
      </Stack>

      <Stack alignItems='flex-end' justifyContent='space-between' direction='row' sx={{ mt: 3 }}>
        <Button disabled={activeStep === 0} variant='contained' onClick={handleBack}>
          Voltar
        </Button>
        <Button variant='contained' onClick={handleSubmit(onSubmit)}>
          Próximo
        </Button>
      </Stack>
    </>
  )
}
