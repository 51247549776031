import isEqual from 'lodash/isEqual'
import { useState, useCallback } from 'react'
// @mui
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'

// routes
import { paths } from 'src/routes/paths'
// types
// components
import { useSettingsContext } from 'src/components/settings'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs'

import Datagrid from 'src/components/datagrid'

//
import { Helmet } from 'react-helmet-async'

import { IOrderTableFilterValue, IOrderTableFilters } from 'src/types/orders'
import useOrders from 'src/service/orders.service'
import OrderTableFiltersResult from './table-user/orders-table-filters-result'
import OrderTableRow from './table-user/orders-table-row'
import OrderTableToolbar from './table-user/orders-table-toolbar'
import OrdersCards from './table-user/orders-cards'

const TABLE_HEAD = [
  { id: '', label: '', width: 80 },
  { id: 'event', label: 'Evento', width: 200 },
  { id: 'saler', label: 'Vendedor', width: 100 },
  { id: 'paymentType', label: 'Tipo de Pagamento', width: 100 },
  { id: 'total', label: 'Total', width: 100 },
  { id: 'tax', label: 'Taxa', width: 100 },
  { id: 'comission', label: 'Comissão', width: 100 },
  { id: 'charge', label: 'Repassar (Charge)', width: 100 },
  { id: 'date', label: 'Data', width: 100 },
]

const defaultPagination = {
  page: 1,
  perPage: 8,
  sort: {
    createdAt: 'desc',
  },
}
const defaultFilters: IOrderTableFilters = {
  event: null,
  dateStart: null,
  dateEnd: null,
  categories: [],
  salers: [],
  paymentType: [],
}

export default function OrdersListView() {
  const [pagination, setPagination] = useState(defaultPagination)
  const [filters, setFilters] = useState<IOrderTableFilters>(defaultFilters)

  const { isLoading, isFetching, data, refetch } = useOrders(pagination, filters)

  const settings = useSettingsContext()

  const canReset = !isEqual(defaultFilters, filters)

  const notFound = (!data?.data.length && canReset) || !data?.data.length

  const handleFilters = useCallback((name: string, value: IOrderTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters)
  }, [])

  return (
    <>
      <Helmet>
        <title>Vendas - Listagem</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Vendas'
          links={[{ name: 'Início', href: paths.dashboard.root }, { name: 'Vendas' }]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Stack spacing={3} direction={{ xs: 'column' }}>
          <OrderTableToolbar filters={filters} onFilters={handleFilters} />

          <OrdersCards data={data?.extraData} isLoading={isLoading || isFetching} />

          <Card>
            {canReset && (
              <OrderTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                onResetFilters={handleResetFilters}
                results={data?.data.length || 0}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}

            <Datagrid
              data={data?.data || []}
              notFound={notFound}
              noSelection
              isLoading={isLoading || isFetching}
              headLabel={TABLE_HEAD}
              refetch={refetch}
              total={data?.total || 0}
              setPagination={setPagination}
              pagination={pagination}
              ItemContent={OrderTableRow}
            />
          </Card>
        </Stack>
      </Container>
    </>
  )
}
