import { useCallback, useState } from 'react'
// @mui
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import useUsers from 'src/service/users.service'
import RHFAutocomplete, { OptionType } from 'src/components/hook-form/rhf-autocomplete'
import Iconify from 'src/components/iconify'
import { useBoolean } from 'src/hooks/use-boolean'
import { LoadingButton } from '@mui/lab'
import UserCreateEditForm from '../../users/user-create-edit-view'

// ----------------------------------------------------------------------

interface EventStepperTwoProps {
  methods: any
  activeStep: number
  handleBack: () => void
  handleNext: (data: any) => void
  isLoading?: boolean
}

interface IEventStepTwo {
  managers: any[]
}

export default function EventStepperTwo({
  activeStep,
  handleBack,
  handleNext,
  methods,
  isLoading,
}: EventStepperTwoProps) {
  const { handleSubmit } = methods

  const [search, setSearch] = useState('')
  const quickEdit = useBoolean()

  const {
    isLoading: isLoadingUser,
    isFetching,
    data,
  } = useUsers(
    {
      page: 1,
      perPage: 100,
      sort: undefined,
    },
    {
      search,
    },
  )

  const onSubmit = useCallback(
    async (payload: IEventStepTwo) => {
      handleNext(payload)
    },
    [handleNext],
  )

  return (
    <>
      <Stack gap={3}>
        <Stack gap={3}>
          <RHFAutocomplete
            options={
              data?.data.map((option) => ({
                label: option.name,
                value: option._id,
              })) || []
            }
            loading={isLoadingUser || isFetching}
            multiple
            name='managers'
            label='Vendedores'
            onInputChange={(event, newInputValue) => {
              setSearch(newInputValue)
            }}
            getOptionLabel={(option: OptionType | string) => (option as OptionType).label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
          />
        </Stack>
        <Stack justifyContent='center' alignItems='flex-end' direction='row'>
          <Button
            size='small'
            color='primary'
            startIcon={<Iconify icon='mingcute:add-line' />}
            onClick={quickEdit.onTrue}
            sx={{ flexShrink: 0 }}
          >
            Adicionar um novo vendedor
          </Button>
        </Stack>
      </Stack>

      <Stack alignItems='flex-end' justifyContent='space-between' direction='row' sx={{ mt: 3 }}>
        <Button disabled={activeStep === 0} variant='contained' onClick={handleBack}>
          Voltar
        </Button>
        <LoadingButton loading={isLoading} variant='contained' type='submit'>
          Próximo
        </LoadingButton>
      </Stack>
      <UserCreateEditForm
        onRefleshTable={() => {}}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />
    </>
  )
}
