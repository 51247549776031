import isEqual from 'lodash/isEqual'
import { useState, useCallback } from 'react'
// @mui
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'

// routes
import { paths } from 'src/routes/paths'
// types
// components
import { useSettingsContext } from 'src/components/settings'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs'

import Datagrid from 'src/components/datagrid'

//
import { Helmet } from 'react-helmet-async'

import { IReportTableFilterValue, IReportTableFilters } from 'src/types/reports'
import useReports from 'src/service/reports.service'
import ProductTableRow from './table-user/reports-table-row'
import ReportTableFiltersResult from './table-user/reports-table-filters-result'
import ReportTableToolbar from './table-user/reports-table-toolbar'

const TABLE_HEAD = [
  { id: 'name', label: 'Produto', width: 200 },
  { id: 'stock', label: 'Estoque inicial', width: 100 },
  { id: 'saida', label: 'Venda total', width: 100 },
  { id: 'price', label: 'Preço de venda', width: 100 },
  { id: 'totalSale', label: 'Total vendido(R$)', width: 100 },
  { id: 'actualStock', label: 'Estoque atual', width: 100 },
  { id: 'cost', label: 'Custo Individual', width: 100 },
  { id: 'stockCost', label: 'Custo de Estoque', width: 100 },
]

const defaultPagination = {
  page: 1,
  perPage: 8,
  sort: {
    createdAt: 'desc',
  },
}
const defaultFilters: IReportTableFilters = {
  event: null,
  dateStart: null,
  dateEnd: null,
}
export default function ReportsListView() {
  const [pagination, setPagination] = useState(defaultPagination)
  const [filters, setFilters] = useState<IReportTableFilters>(defaultFilters)

  const { isLoading, isFetching, data, refetch } = useReports(pagination, filters)

  const settings = useSettingsContext()

  const canReset = !isEqual(defaultFilters, filters)

  const notFound = (!data?.length && canReset) || !data?.length

  const handleFilters = useCallback((name: string, value: IReportTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters)
  }, [])

  return (
    <>
      <Helmet>
        <title>Relatório de produtos - Listagem</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Relatório de produtos'
          links={[
            { name: 'Início', href: paths.dashboard.root },
            { name: 'Relatório de produtos' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <ReportTableToolbar filters={filters} onFilters={handleFilters} />

          {canReset && (
            <ReportTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={data?.length || 0}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Datagrid
            data={data || []}
            notFound={notFound}
            noSelection
            isLoading={isLoading || isFetching}
            headLabel={TABLE_HEAD}
            refetch={refetch}
            total={data?.length || 0}
            setPagination={setPagination}
            pagination={pagination}
            ItemContent={ProductTableRow}
          />
        </Card>
      </Container>
    </>
  )
}
