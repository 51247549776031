// eslint-disable

import { useQuery } from '@tanstack/react-query'
import { Pagination } from 'src/components/datagrid'
import { ICategorieItem } from 'src/types/categorie'

import axiosInstance from 'src/utils/axios'

type Categories = {
  data: ICategorieItem[]
  page: number
  perPage: number
  totalPages: number
  total: number
}

async function fetchUser(pagination: Pagination, filters: any): Promise<Categories> {
  let sortName: any = 'createdAt'
  let order: string = 'desc'

  if (pagination.sort) {
    const keyNames = Object.keys(pagination.sort)
    sortName = keyNames[0]
    order = pagination.sort[sortName]
  }

  const res = await axiosInstance.get('/category', {
    params: {
      ...pagination,
      ...filters,
      sort: sortName,
      order: order === 'desc' ? -1 : 1,
    },
  })
  return res.data
}

export async function getCategoriesToAutocomplete(
  search: string,
  event: string,
): Promise<Categories> {
  const res = await axiosInstance.get('/category', {
    params: {
      filter: search,
      perPage: 50,
      event,
    },
  })

  const fixedData = res?.data?.data?.map((category: ICategorieItem) => ({
    label: category.name,
    value: category._id,
  }))
  return fixedData
}

function useCategories(pagination: Pagination, filters?: any) {
  return useQuery(['categorie', pagination, filters], () => fetchUser(pagination, filters), {
    keepPreviousData: true,
    staleTime: 5000,
  })
}

export default useCategories
