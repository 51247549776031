// eslint-disable

import { useQuery } from '@tanstack/react-query'
import { Pagination } from 'src/components/datagrid'
import { IOrderItem } from 'src/types/orders'

import axiosInstance from 'src/utils/axios'

type Orders = {
  data: IOrderItem[]
  extraData: any
  page: number
  perPage: number
  totalPages: number
  total: number
}

async function fetchUser(pagination: Pagination, filters: any): Promise<Orders> {
  let sortName: any = 'createdAt'
  let order: string = 'desc'

  if (pagination.sort) {
    const keyNames = Object.keys(pagination.sort)
    sortName = keyNames[0]
    order = pagination.sort[sortName]
  }

  const res = await axiosInstance.get('/order', {
    params: {
      ...pagination,
      ...filters,
      sort: sortName,
      order: order === 'desc' ? -1 : 1,
      event: filters?.event?.value,
      salers: filters?.salers?.map((item: any) => item.value),
      categories: filters?.categories?.value,
      paymentType: filters?.paymentType?.map((item: any) => JSON.parse(item).value),
    },
  })
  return res.data
}

function useOrders(pagination: Pagination, filters?: any) {
  return useQuery(['orders', pagination, filters], () => fetchUser(pagination, filters), {
    keepPreviousData: true,
    staleTime: 12000,
  })
}

export default useOrders
