import { useEffect, useCallback, useMemo } from 'react'
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios'
//
import { useSnackbar } from 'src/components/snackbar'

import { useAuthStore } from 'src/store/auth'
import { useUserStore } from 'src/store/user'

import { AuthContext } from './auth-context'

import { isValidToken, setSession } from './utils'

type Props = {
  children: React.ReactNode
}

export function AuthProvider({ children }: Props) {
  const { loading, setLoading } = useAuthStore()

  const { enqueueSnackbar } = useSnackbar()

  const userStorage = useUserStore((state) => state.user)

  const { signed } = useAuthStore()
  const { setUser } = useUserStore()

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null)
    useAuthStore.getState().logout()
    useUserStore.getState().logout()
  }, [])

  const initialize = useCallback(async () => {
    try {
      const accessToken = useAuthStore.getState().token

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken)

        const { data: response } = await axios.get(API_ENDPOINTS.auth.me)

        setUser(response)
      }
    } catch (error) {
      logout()
      useAuthStore.getState().setLoading(false)
    }
  }, [logout, setUser])

  useEffect(() => {
    initialize()
  }, [initialize])

  // LOGIN
  const login = useCallback(
    async (email: string, password: string, returnTo: string | null) => {
      setLoading(true)
      const data = {
        email,
        password,
      }

      const { data: response } = await axios.post(API_ENDPOINTS.auth.login, data)

      if (response.user.role === 'admin' || response.user.role === 'franchisee') {
        axios.defaults.headers.Authorization = `Bearer ${response.token}`
        setUser(response.user)
        setSession(response.token)
      }
    },
    [setUser, setLoading],
  )

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, name: string) => {
      setLoading(true)

      const data = {
        email,
        password,
        name,
      }

      await axios.post(API_ENDPOINTS.auth.register, data)

      const dataLogin = {
        email,
        password,
      }

      const { data: response } = await axios.post(API_ENDPOINTS.auth.login, dataLogin)

      setUser(response.data)
      setSession(response.token)
    },
    [setUser, setLoading],
  )

  // ----------------------------------------------------------------------

  const checkAuthenticated = signed ? 'authenticated' : 'unauthenticated'

  const status = loading ? 'loading' : checkAuthenticated

  const memoizedValue = useMemo(
    () => ({
      user: userStorage,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      register,
      logout,
    }),
    [login, logout, register, userStorage, status],
  )

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}
