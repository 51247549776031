import isEqual from 'lodash/isEqual'
import { useState, useCallback } from 'react'
// @mui
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { alpha } from '@mui/material/styles'

// routes
import { paths } from 'src/routes/paths'
import { useRouter } from 'src/routes/hook'
import { RouterLink } from 'src/routes/components'
// types
import { IUserTableFilterValue } from 'src/types/user'
// components
import Iconify from 'src/components/iconify'
import { useSettingsContext } from 'src/components/settings'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs'

import Datagrid from 'src/components/datagrid'

//
import { Helmet } from 'react-helmet-async'
import useUsers from 'src/service/users.service'
import axiosInstance from 'src/utils/axios'
import { useSnackbar } from 'notistack'
import { useBoolean } from 'src/hooks/use-boolean'
import UserTableRow from './table-user/user-table-row'
import UserTableToolbar from './table-user/user-table-toolbar'
import UserTableFiltersResult from './table-user/user-table-filters-result'
import UserCreateEditForm from './user-create-edit-view'

export const USER_STATUS_OPTIONS = [
  { value: 'true', label: 'Ativos' },
  { value: 'false', label: 'Desativados' },
]

const _roles = [
  { value: 'admin', label: 'Admin' },
  { value: 'user', label: 'Usuário' },
  { value: 'franchisee', label: 'Franqueado' },
]

const STATUS_OPTIONS = [{ value: 'all', label: 'Todos' }, ...USER_STATUS_OPTIONS]

const TABLE_HEAD = [
  { id: 'name', label: 'Nome' },
  { id: 'document', label: 'Documento', width: 180 },
  { id: 'comission', label: 'Comissão (%)', width: 180 },
  { id: 'role', label: 'Tipo', width: 180 },
  { id: 'status', label: 'Status', width: 100 },
  { id: 'createdAt', label: 'Data de criação', width: 180 },
  { id: '', width: 88 },
]

const defaultPagination = {
  page: 1,
  perPage: 8,
  sort: {
    createdAt: 'desc',
  },
}
const defaultFilters = {
  search: '',
  role: [],
  active: 'all',
}
export default function UserListView() {
  const [pagination, setPagination] = useState(defaultPagination)
  const [filters, setFilters] = useState(defaultFilters)

  const { isLoading, isFetching, data, refetch } = useUsers(pagination, filters)

  const settings = useSettingsContext()

  const { enqueueSnackbar } = useSnackbar()

  const router = useRouter()

  const quickEdit = useBoolean()

  const canReset = !isEqual(defaultFilters, filters)

  const notFound = (!data?.data.length && canReset) || !data?.data.length

  const handleFilters = useCallback((name: string, value: IUserTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  const handleDeleteRow = useCallback(
    async (id: string) => {
      try {
        await axiosInstance.delete(`/user/${id}`)

        enqueueSnackbar('Usuario removido com sucesso !')
        refetch()
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro ao remover usuario !', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, refetch],
  )

  const handleDeleteRows = useCallback(
    async (selectedValues: string[]) => {
      try {
        selectedValues.map(async (id) => {
          await axiosInstance.delete(`/user/${id}`)
        })

        enqueueSnackbar('Usuarios removido com sucesso !')
        refetch()
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro ao remover usuario !', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, refetch],
  )

  const handleEditRow = useCallback((id: string) => {
    // router.push(paths.dashboard.user.edit(id))
  }, [])

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('active', newValue)
    },
    [handleFilters],
  )

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters)
  }, [])

  return (
    <>
      <Helmet>
        <title>Usuarios - Listagem</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Usuários'
          links={[{ name: 'Início', href: paths.dashboard.root }, { name: 'Usuários' }]}
          action={
            <Button
              component={RouterLink}
              onClick={() => quickEdit.onTrue()}
              variant='contained'
              startIcon={<Iconify icon='mingcute:add-line' />}
            >
              Novo usuario
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.active}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab.value} iconPosition='end' value={tab.value} label={tab.label} />
            ))}
          </Tabs>
          <UserTableToolbar filters={filters} onFilters={handleFilters} roleOptions={_roles} />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={data?.data.length || 0}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Datagrid
            data={data?.data || []}
            notFound={notFound}
            isLoading={isLoading || isFetching}
            headLabel={TABLE_HEAD}
            refetch={refetch}
            total={data?.total || 0}
            setPagination={setPagination}
            pagination={pagination}
            handleDeleteRows={handleDeleteRows}
            ItemContent={UserTableRow}
            handleDeleteRow={handleDeleteRow}
            handleEditRow={handleEditRow}
          />
        </Card>
      </Container>
      <UserCreateEditForm
        onRefleshTable={() => refetch()}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />
    </>
  )
}
