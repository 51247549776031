import axios from 'axios'
// config
import { HOST_API } from 'src/config-global'
import { useAuthStore } from 'src/store/auth'

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API })

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
)

axiosInstance.interceptors.request.use(
  async (config) => {
    const { token } = useAuthStore.getState()
    config.headers.set('Authorization', `Bearer ${token}`)
    return config
  },
  (error) => Promise.reject(error),
)
export default axiosInstance

export const API_ENDPOINTS = {
  auth: {
    me: '/user/me',
    login: '/user/login',
    register: '/user',
    forgot: '/forgot',
  },
  user: {
    editMe: '/user/me',
    editPhoto: '/user/me/upload',
  },
}
