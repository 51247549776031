// @mui
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
// types
// components
import Label from 'src/components/label'
import Iconify from 'src/components/iconify'
import { ConfirmDialog } from 'src/components/custom-dialog'
import { format } from 'date-fns'

import { IEventItem } from 'src/types/events'
//

// ----------------------------------------------------------------------

export const roleName = {
  admin: 'Administrador',
  user: 'Usuário',
  franchisee: 'Franqueado',
}

type Props = {
  selected: boolean
  onEditRow: VoidFunction
  row: IEventItem
  refetch: VoidFunction
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
}

export default function EventTableRow({
  row,
  selected,
  refetch,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, date, active, createdAt } = row

  const confirm = useBoolean()

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding='checkbox'>
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>{name}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {date ? format(new Date(date), 'dd/MM/yyyy') : ''}
        </TableCell>

        <TableCell>
          <Label
            variant='soft'
            color={(active && 'success') || (!active && 'warning') || 'default'}
          >
            {active ? 'Ativo' : 'Inativo'}
          </Label>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}
        </TableCell>

        <TableCell align='right' sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title='Editar' placement='top' arrow>
            <IconButton onClick={() => onEditRow()}>
              <Iconify icon='solar:pen-bold' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Deletar' placement='top' arrow>
            <IconButton
              onClick={() => {
                confirm.onTrue()
              }}
            >
              <Iconify color='red' icon='solar:trash-bin-trash-bold' />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Tem certeza que quer deletar?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteRow}>
            Deletar
          </Button>
        }
      />
    </>
  )
}
